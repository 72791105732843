@import-normalize;

@font-face {
  font-family: "Axiforma";
  src: local("Axiforma Black"), local("Axiforma-Black"),
    url("./assets/fonts/Axiforma-Black/Axiforma-Black.woff2") format("woff2"),
    url("./assets/fonts/Axiforma-Black/Axiforma-Black.woff") format("woff"),
    url("./assets/fonts/Axiforma-Black/Axiforma-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Axiforma";
  src: local("Axiforma Bold"), local("Axiforma-Bold"),
    url("./assets/fonts/Axiforma-Bold/Axiforma-Bold.woff2") format("woff2"),
    url("./assets/fonts/Axiforma-Bold/Axiforma-Bold.woff") format("woff"),
    url("./assets/fonts/Axiforma-Bold/Axiforma-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Axiforma";
  src: local("Axiforma Medium"), local("Axiforma-Medium"),
    url("./assets/fonts/Axiforma-Medium/Axiforma-Medium.woff2") format("woff2"),
    url("./assets/fonts/Axiforma-Medium/Axiforma-Medium.woff") format("woff"),
    url("./assets/fonts/Axiforma-Medium/Axiforma-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Axiforma";
  src: local("Axiforma Regular"), local("Axiforma-Regular"),
    url("./assets/fonts/Axiforma-Regular/Axiforma-Regular.woff2")
      format("woff2"),
    url("./assets/fonts/Axiforma-Regular/Axiforma-Regular.woff") format("woff"),
    url("./assets/fonts/Axiforma-Regular/Axiforma-Regular.ttf")
      format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Heavy"), local("Gilroy-Heavy"),
    url("./assets/fonts/Gilroy-Black/Gilroy-Heavy.woff2") format("woff2"),
    url("./assets/fonts/Gilroy-Black/Gilroy-Heavy.woff") format("woff"),
    url("./assets/fonts/Gilroy-Black/Gilroy-Heavy.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Bold"), local("Gilroy-Bold"),
    url("./assets/fonts/Gilroy-Bold/Gilroy-Bold.woff2") format("woff2"),
    url("./assets/fonts/Gilroy-Bold/Gilroy-Bold.woff") format("woff"),
    url("./assets/fonts/Gilroy-Bold/Gilroy-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Medium"), local("Gilroy-Medium"),
    url("./assets/fonts/Gilroy-Medium/Gilroy-Medium.woff2") format("woff2"),
    url("./assets/fonts/Gilroy-Medium/Gilroy-Medium.woff") format("woff"),
    url("./assets/fonts/Gilroy-Medium/Gilroy-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Regular"), local("Gilroy-Regular"),
    url("./assets/fonts/Gilroy-Regular/Gilroy-Regular.woff2") format("woff2"),
    url("./assets/fonts/Gilroy-Regular/Gilroy-Regular.woff") format("woff"),
    url("./assets/fonts/Gilroy-Regular/Gilroy-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
  color: currentColor;
}

image {
  display: block;
  max-width: 100%;
  height: auto;
}

body {
  margin: 0;
  font-family: "Gilroy", "Montserrat", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.axiforma {
  font-family: "Axiforma";
  font-weight: 700;
  font-size: 24px;
}

.ukrainianWordBlack {
  font-family: "Gilroy";
  font-weight: 900;
}

.ukrainianWordBold {
  font-family: "Gilroy";
  font-weight: 700;
}

.ukrainianWordMedium {
  font-family: "Gilroy";
  font-weight: 500;
}

.ukrainianWordRegular {
  font-family: "Gilroy";
  font-weight: 400;
}

@keyframes blackComing {
  0% {
    background-color: transparent;
  }

  100% {
    background-color: rgba(0, 0, 0, 0.7);
  }
}

@keyframes dislayHidden {
  0% {
    display: flex !important;
  }

  100% {
    display: none !important;
  }
}

.visible {
  transform: translateX(0);
  pointer-events: all;
  transition: all 0.6s ease-in-out;
  animation: blackComing forwards;
  animation-delay: 0.6s;
  animation-duration: 0.6s;
}

.header-animation {
  pointer-events: none;
  transform: translateX(480px);
  transition: all 0.6s ease-in-out;
}

.displayNone {
  animation: dislayHidden 0.6s forwards;
}

.displayHidden {
  animation: dislayHidden 0.6s forwards;
}

.underlined {
  text-decoration-line: underline;
}

@keyframes scaling {
  0% {
    opacity: 0;
    transform: scale(0.7);
    transform: translate(-50%, -50%);
  }

  20% {
    opacity: 1;
    transform: scale(0.7);
    transform: translate(-50%, -50%);
  }

  100% {
    opacity: 1;
    transform: scale(1);
    transform: translate(-50%, -50%);
  }
}

@keyframes scalingDesc {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }

  20% {
    opacity: 1;
    transform: scale(0.8);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.language-animation {
  animation: scaling 3.5s;
}

.language-animationDesc {
  animation: scalingDesc 0.5s;
}
